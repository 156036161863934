<template>
    <div class="yemain">
        <div class="logo">
            <el-image v-if="logo != ''" style="width: 200px; height: 60px" :src="logo"></el-image>
        </div>

        <div class="login">
            <span class="login_title login_title_t">运营管理平台</span>
            <span class="login_title login_title_tip">{{ tip }}</span>
            <el-input v-model="username" placeholder="请输入账号" maxlength="26"></el-input>
            <div style="height: 20px;"></div>
            <el-input v-model="password" placeholder="请输入密码" maxlength="26" show-password></el-input>
            <div style="height: 20px;"></div>
            <el-button class="login_input" type="primary" @click="login" >登录</el-button>
            <div style="height: 20px;"></div>


        </div>
        <div class="footer">
            <span class="footer-sapn">2020-{{ year }} © 练市集 . 管理系统<a
                    class="footer-sapn"
                    style="color: #000; text-decoration:none; margin-left: 20px;"
                    target="_blank"
                    href="http://www.beian.miit.gov.cn"
            >{{ this.env.ICP }}</a><span v-if="this.env.DE_BUG==='true'" style="color: #cf1322;">【开发测试版】</span></span>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            edition_tip: '123',
            username: '',
            password: '',
            year: new Date().getFullYear(),
            tip: '练市集',
            logo: '',
        }
    },
    // 初始化加载
    created: function () {
        document.title = 'OSS登录'
        console.log()
        this.init()
    },
    mounted(){
        //绑定事件
        window.addEventListener('keydown',this.keyDown);
    },

    methods: {
        //回车事件
        keyDown(e){
            //如果是回车则执行登录方法
            if(e.keyCode == 13){
                this.login();
            }
        },
        // 初始化
        init() {
            console.log('登录页面-判断是否已经登录')
            const token = this.Tool.get_l_cache('token')
            if (token != null) {
                location.href = '/home'
            }
        },
        // 登录
        login() {
            // 获取登录信息
            let postdata = {
                api_name: 'home.user.login',
                mobile: this.username,
                password: this.password,
            }
            // console.log(postdata)
            // 请求登录接口
            this.Tool.post_data('oss', postdata, (json) => {
                // console.log('返回数据',json)
                // 处理登录结果
                if (json.code === 0) {
                    this.loginok(json.data)
                } else {
                    console.log('登录失败')
                    this.$message.error(json.message);
                }
            })
        },
        // 登录成功
        loginok(data) {
            // 保存用户信息
            this.Tool.set_l_cache('token', data.userinfo.token);
            this.Tool.set_l_cache('userinfo', data.userinfo);
            // 保存用户权限
            this.Tool.set_l_cache('userauth', data.userauth);
            // 保存用户菜单
            this.Tool.set_l_cache('usermenu', data.usermenu);
            // 跳转页面
            location.href = '/home'
            // this.$router.push('/home')
            // console.log(data)
        }
    },

}
</script>

<style scoped>
.logo {
    margin-top: 150px;
    width: auto;
    text-align: center;
}

.yemain {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.9;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: #e9eef3;
    /*background-image: url("");*/
}

.login {
    background-color: #fff;
    /*box-shadow: 2px 2px 15px #000;*/
    width: 300px;
    margin: auto;
    margin-top: 50px;
    padding: 20px 25px;
    text-align: center;
}

.login_input {
    margin-top: 10px !important;
    width: 100% !important;
}

.login_title {
    display: block;
    font-size: 14px;
    margin: 20px 0;
}

.login_title_t {
    font-size: 24px;
}

.login_title_tip {
    color: #ff9900;
}

.login_but {
    width: 100%;
    margin: 20px 0;
}

.footer {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: #000;
    background: rgba(255, 255, 255, 0.2);
    font-size: 14px;
}
</style>
