<template>
    <div>
        <div class="e404">
            <div class="e404-tip">
                <div class="e404-tip-img">
                    <img src="@/assets/notfound.png" height="300" width="400"/>
                </div>
                <div class="e404-tip-text">
                    <h1>{{ msg }}</h1>
                    <span style="display: block;">{{ msg2 }}</span>
                    <el-button style="margin-top: 20px; " type="primary" size="small" @click="to_return">
                        {{ referrer === '' ? '返回首页' : '返回' }}
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotFoundPage',
    data() {
        return {
            msg: '404',
            msg2: '您访问的页面不存在',
            referrer: '',
        }
    },
    // 初始化加载
    created: function () {
        this.referrer = document.referrer
    },
    methods: {
        // 返回
        to_return() {
            if (document.referrer === '') {     // 判断有没有上级路由；
                location.href = '/'             // 返回首页
            } else {
                this.$router.go(-1);        // 返回上级路由
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.e404 {
    width: 100%;
    display: flex;
    justify-content: center;
}

.e404-tip {
    display: flex;
    /*background: #42b983;*/
    /*width: 200px;*/
    /*height: 200px;*/
    margin-top: 200px;
}

.e404-tip-text {
    display: flex;
    flex-direction: column;
    justify-content: center;;
}

</style>
