import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Tool from './custom/tool'    // 自定义工具
import env from '/config/env'    // 自定义工具
import 'font-awesome/css/font-awesome.min.css'
// 引入element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);
import 'mavon-editor/dist/css/index.css'

import VueCropper from 'vue-cropper'
Vue.use(VueCropper)

// 图片放大
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

Vue.use(Viewer)
Viewer.setDefaults({
    'inline': false, //启用inline模式
    'button': false, //显示右上角关闭按钮
    'navbar': false, //显示缩略图导航
    'title': false, //显示当前图片的标题
    'toolbar': false, //显示工具栏
    'tooltip': true, //显示缩略百分比
    'movable': true, //图片是否可移动
    'zoomable': true, //图片是否可缩放
    'rotatable': true, //图片是否可旋转
    'scalable': true, //图片是否可反转
    'transition': true, //使用css3过度
    'fullscreen': false, //播放时是否全屏
    'keyboard': true, //
})

Vue.config.productionTip = false
Vue.prototype.Tool = Tool
Vue.prototype.env = env

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
